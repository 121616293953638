import {useQuery, type SkipToken, type UseQueryOptions, type UseQueryResult} from '@tanstack/react-query'
import {useLoaderData} from 'react-router-dom'
import type {RouteQueryKey} from '../query-key'

type QueryOptionsWithKey = UseQueryOptions<unknown, Error, unknown> & {
  initialData?: unknown
} & {
  queryKey: RouteQueryKey
  queryFn: Exclude<UseQueryOptions<unknown, Error, unknown>['queryFn'], SkipToken>
}

type UseQueryResultWithKey<T> = UseQueryResult<T> & {
  queryKey: RouteQueryKey
}

export function useQueriesConfigs() {
  return useLoaderData() as Record<string, QueryOptionsWithKey>
}

export function useQueriesConfig(name: string) {
  const ctx = useQueriesConfigs()
  if (!ctx[name]) {
    throw new Error(`No query named ${name} is defined in the route configuration.`)
  }
  return ctx[name]
}

export function useRouteQuery<T>(name: string) {
  const config = useQueriesConfig(name)
  // Casting because config is not typed, and generic arguments to the `useQuery` hook would attempt to validate against the queryFn types.
  return {...useQuery(config), queryKey: config.queryKey} as UseQueryResultWithKey<T>
}
