import {startSoftNav} from '@github-ui/soft-nav/state'
import type {useQuery} from '@tanstack/react-query'
import {queryOptions} from '@tanstack/react-query'
import type React from 'react'
import {
  generatePath,
  type IndexRouteObject,
  type LoaderFunctionArgs,
  type NonIndexRouteObject,
  type Params,
  type RouteObject,
} from 'react-router-dom'
import {getQueryClient} from '../query-client'
import {makeQueryKey} from '../query-key'
import {ssrSafeLocation} from '@github-ui/ssr-utils'

type QueryLoaderArgs = Omit<LoaderFunctionArgs, 'request'> & {
  url: string
  searchParams: URLSearchParams
}

type BaseRouteConfiguration = Pick<
  RouteObject,
  'errorElement' | 'hydrateFallbackElement' | 'id' | 'lazy' | 'shouldRevalidate' | 'HydrateFallback' | 'ErrorBoundary'
> & {
  Component: React.ComponentType
  path: `/${string}`
  queries?:
    | false
    | {
        [queryName: string]: (args: QueryLoaderArgs) => Omit<Parameters<typeof useQuery>[0], 'queryKey'>
      }
}

type IndexRouteConfiguration = BaseRouteConfiguration & Pick<IndexRouteObject, 'children' | 'index'>

type NonIndexRouteConfiguration = BaseRouteConfiguration &
  Pick<NonIndexRouteObject, 'index'> & {children?: RouteObject[]}

export type RouteConfiguration = IndexRouteConfiguration | NonIndexRouteConfiguration

export function createAppScopedQueryRoute(appName: string) {
  return function createQueryRoute(route: RouteConfiguration): RouteObject {
    // split these codepaths to make typescript happy, otherwise we can't discriminate over the valid signatures
    if (route.index) {
      return queryRoute(appName, route)
    }
    return queryRoute(appName, route)
  }
}

/**
 * Extracts parts from a URL given the path match and params
 * By default, the request object given to the loader will always be the url of the leaf, regardless of level
 */
function extractURLParts(request: Request, pathMatch: string, params: Params) {
  const constructedRequestPath = generatePath(pathMatch, params)
  const {searchParams} = new URL(request.url, ssrSafeLocation.origin)

  return {
    url: constructedRequestPath,
    searchParams,
  }
}

/**
 * Query route factory that transforms a route configuration object to a react-router-dom compatible route object
 */
function queryRoute(appName: string, indexRoute: IndexRouteConfiguration): IndexRouteObject
function queryRoute(appName: string, nonIndexRoute: NonIndexRouteConfiguration): NonIndexRouteObject
function queryRoute(appName: string, {queries, Component, ...route}: RouteConfiguration): RouteObject {
  return {
    ...route,
    element: <Component />,
    loader: async ({request, params, context}) => {
      startSoftNav('react')
      const {url, searchParams} = extractURLParts(request, route.path, params)
      const queryDeps = {params, searchParams: Object.fromEntries(searchParams)}
      const promises: Array<Promise<unknown>> = []

      const queryConfigs = Object.entries(queries || {}).map(([queryName, queryConfig]) => {
        const config = queryConfig({
          url,
          params,
          context,
          searchParams,
        })
        const options = queryOptions({
          queryKey: makeQueryKey({appName, path: route.path, queryName, queryDeps}),
          ...config,
        })

        promises.push(getQueryClient().fetchQuery(options))

        return [queryName, options] as const
      })

      await Promise.all(promises)

      return Object.fromEntries(queryConfigs)
    },
  }
}
